@import './custom';
@import '~bootstrap/scss/bootstrap';

$bgLight: #f8f9fa;
$lightGrey: #eeeeee;
$darkGrey: #232F34;

body, #root {
  background-color: $lightGrey;
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
}

.logo {
  display: inline-block;
  margin-right: 0.5rem;
  width: 32px;
  height: 32px;
}

.logo:hover {
  cursor: pointer;
}

.code-block {
  background-color: hsl(0, 0%, 97%)
}

canvas {
  display: inline-block;
}

.material, .material-static {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.2s cubic-bezier(.25,.8,.25,1);
}

.material:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

canvas.chart {
  // padding: 0 15% 0 15%;
  margin: 0 15% 0 15%;
  display: block;
  // margin: 0 auto;
  width: 70%;
}

#canvas-banner {
  padding-top: 0;
  margin-top: 0;
  width: 100%;
  display: block;
  height: 1rem;
}

.palette-wrapper {
  z-index: 1;
  margin: 10px 0px 10px 0px;
  border-radius: 3px;
  padding: 15px;
  background-color: white;
}

.chart-wrapper {
  margin: 0;
  border-radius: 4px;
  background-color: white;
  padding: 15px
}

div.chart {
  position: relative;
}


h2 {
  text-align: left;
  font-size: 1.5rem;
  color: $darkGrey;
}

.palette {
  width: 100%;
  height: 2.5rem;
}

.preset {
  height: 2.5rem;
}

.editor-palette {
  height: 3.5rem;
}

.pinned-switch {
  margin-bottom: 0.5rem;
}

form {
  margin-top: .75rem;
}

.form-group {
  margin-bottom: .75rem;
}

button:focus {
  outline: none;
}

.smart-input-label, .reset-input-button {
  background-color: #eee;
}

.smart-input-label:hover, .reset-input-button:hover {
  background-color: darken(#eee, 15%);
}